// client/src/components/SendMessage.jsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import './SendMessage.css';

const SendMessage = () => {
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState(''); // 'success', 'error', or 'info'
  const [selectAll, setSelectAll] = useState(false);
  const [sending, setSending] = useState(false);
  const [results, setResults] = useState(null);
  const [messageType, setMessageType] = useState('text'); // 'text' or 'media'
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaPreview, setMediaPreview] = useState('');
  const fileInputRef = useRef(null);
  const [messageJobId, setMessageJobId] = useState(null);
  const [messageProgress, setMessageProgress] = useState(null);
  const [pollingInterval, setPollingInterval] = useState(null);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [scheduleTime, setScheduleTime] = useState('');
  
  // Fetch groups on component mount
  useEffect(() => {
    fetchGroups();
  }, []);
  
  // Handle select all checkbox
  useEffect(() => {
    if (selectAll) {
      setSelectedGroups(groups.map(group => group.groupId));
    } else if (selectedGroups.length === groups.length) {
      // If all were selected and select all is unchecked, clear selection
      setSelectedGroups([]);
    }
  }, [selectAll]);
  
  // Update selectAll state when individual selections change
  useEffect(() => {
    if (groups.length > 0 && selectedGroups.length === groups.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedGroups, groups]);
  
  // Cleanup polling interval when component unmounts
  useEffect(() => {
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [pollingInterval]);
  
  const fetchGroups = async () => {
    setLoading(true);
    setStatusMessage('');
    
    try {
      // Get current session ID from sessionStorage or context
      const sessionId = sessionStorage.getItem('currentSessionId');
      
      if (!sessionId) {
        setStatusMessage('No active session found. Please connect WhatsApp first.');
        setStatusType('error');
        setLoading(false);
        return;
      }
      
      const response = await fetch(`/api/groupChats/${sessionId}`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      
      if (data.success) {
        setGroups(data.groups || []);
      } else {
        setStatusMessage(data.message || 'Failed to fetch group chats');
        setStatusType('error');
      }
    } catch (err) {
      setStatusMessage('Error fetching group chats: ' + err.message);
      setStatusType('error');
      console.error('Error fetching group chats:', err);
    } finally {
      setLoading(false);
    }
  };
  
  const handleGroupSelection = (groupId) => {
    if (selectedGroups.includes(groupId)) {
      setSelectedGroups(selectedGroups.filter(id => id !== groupId));
    } else {
      setSelectedGroups([...selectedGroups, groupId]);
    }
  };
  
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
  };
  
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleMessageTypeChange = (type) => {
    setMessageType(type);
    setMessage('');
    setMediaFile(null);
    setMediaPreview('');
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setMediaFile(file);

    // Create preview for images
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setMediaPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setMediaPreview('');
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        // Extract base64 data without the prefix
        const base64Data = reader.result.split(',')[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  
  // Function to check job status
  const checkJobStatus = useCallback(async (jobId) => {
    try {
      const response = await fetch(`/api/message-jobs/${jobId}`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      
      const data = await response.json();
      
      if (data.success) {
        setMessageProgress(data.job);
        
        // If job is complete, stop polling and update UI
        if (data.job.status === 'completed' || data.job.status === 'failed') {
          clearInterval(pollingInterval);
          setPollingInterval(null);
          
          let statusMsg = data.job.status === 'completed' ? 
            `Message sending complete! Successfully sent to ${data.job.results.success} groups.` :
            `Message sending failed. Successfully sent to ${data.job.results.success} groups. Failed for ${data.job.results.failed} groups.`;
          
          if (data.job.results.failed > 0) {
            statusMsg += " You can retry failed messages from the Jobs page.";
          }
          
          setStatusMessage(statusMsg);
          setStatusType(data.job.status === 'completed' ? 'success' : 'error');
          setSending(false);
          
          // Clear message fields after successful send
          if (messageType === 'text') {
            setMessage('');
          } else {
            setMediaFile(null);
            setMediaPreview('');
            setMessage('');
          }
          setSelectedGroups([]);
          setMessageJobId(null);
          setMessageProgress(null);
        }
      } else {
        console.error('Error checking job status:', data.message);
      }
    } catch (err) {
      console.error('Error checking job status:', err);
    }
  }, [pollingInterval, messageType]);
  
  const sendMessage = async (isScheduled = false) => {
    // Validation
    if (selectedGroups.length === 0) {
      setStatusMessage('Please select at least one group');
      setStatusType('error');
      return;
    }
    
    if (messageType === 'text' && !message.trim()) {
      setStatusMessage('Please enter a message');
      setStatusType('error');
      return;
    }

    if (messageType === 'media' && !mediaFile) {
      setStatusMessage('Please select a file to send');
      setStatusType('error');
      return;
    }
    
    // If scheduling, validate schedule time
    if (isScheduled) {
      if (!scheduleTime.trim()) {
        setStatusMessage('Please select a schedule time');
        setStatusType('error');
        return;
      }
      
      const scheduledDateTime = new Date(scheduleTime);
      if (isNaN(scheduledDateTime) || scheduledDateTime <= new Date()) {
        setStatusMessage('Please select a valid future date and time');
        setStatusType('error');
        return;
      }
    }
    
    // Close schedule modal if it's open
    setIsScheduleModalOpen(false);
    
    setSending(true);
    setStatusMessage(isScheduled ? 'Scheduling message...' : 'Initiating message sending...');
    setStatusType('info');
    setResults(null);
    
    try {
      const sessionId = sessionStorage.getItem('currentSessionId');
      
      if (!sessionId) {
        setStatusMessage('No active session found');
        setStatusType('error');
        setSending(false);
        return;
      }

      // Prepare the request payload
      const payload = {
        chatIds: selectedGroups,
        type: messageType === 'text' ? 'string' : 'media'
      };

      // 2. Update the sendMessage function to handle the timezone when sending to server
      // In the sendMessage function, where you set up the payload for scheduling:
      if (isScheduled) {
        // Convert the local datetime input to an ISO string for the server
        // This adds the timezone information explicitly
        const scheduledDate = new Date(scheduleTime);
        payload.scheduleTime = scheduledDate.toISOString();
      }

      // Handle different message types
      if (messageType === 'text') {
        payload.content = message;
      } else if (messageType === 'media') {
        // Get the base64 data from the file
        const base64Data = await readFileAsBase64(mediaFile);
        
        // Set mime type based on file type
        let mime = mediaFile.type;
        if (mediaFile.type.startsWith('image/')) {
          mime = 'image/jpeg'; // Default for images
          if (mediaFile.type === 'image/png') {
            mime = 'image/png';
          } else if (mediaFile.type === 'image/gif') {
            mime = 'image/gif';
          }
        } else if (mediaFile.type.startsWith('video/')) {
          mime = 'video/mp4'; // Default for videos
        } else if (mediaFile.type.startsWith('audio/')) {
          mime = 'audio/mp3'; // Default for audio
        } else {
          mime = 'application/octet-stream'; // Default for other files
        }

        payload.mime = mime;
        payload.data = base64Data;
        payload.content = message;
      }
      
      // Send the request to initiate the bulk message job
      const response = await fetch(`/api/session/${sessionId}/sendBulkMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(payload),
      });
      
      const data = await response.json();
      
      if (data.success) {
        if (isScheduled) {
          setStatusMessage(`Message scheduled successfully! Scheduled for ${new Date(scheduleTime).toLocaleString()}`);
          setStatusType('success');
          setSending(false);
          
          // Clear fields after successful scheduling
          setMessage('');
          setMediaFile(null);
          setMediaPreview('');
          setSelectedGroups([]);
        } else {
          setStatusMessage(`Sending messages to ${data.totalChats} groups...`);
          setStatusType('info');
          
          // Store the job ID for status checking
          setMessageJobId(data.jobId);
          
          // Start polling for job status
          const intervalId = setInterval(() => {
            checkJobStatus(data.jobId);
          }, 2000); // Check every 2 seconds
          
          setPollingInterval(intervalId);
        }
      } else {
        setStatusMessage(data.message || 'Failed to send message');
        setStatusType('error');
        setSending(false);
      }
    } catch (err) {
      setStatusMessage('Error sending message: ' + err.message);
      setStatusType('error');
      console.error('Error sending message:', err);
      setSending(false);
    }
  };
  
  const handleScheduleClick = () => {
    // Get the current date and time, adjusted for local timezone
    const now = new Date();
    // Add 30 minutes
    now.setMinutes(now.getMinutes() + 30);
    
    // Format for datetime-local input (YYYY-MM-DDThh:mm)
    // This ensures the local timezone is preserved when displayed
    const localDateString = now.toISOString().slice(0, 16);
    
    setScheduleTime(localDateString);
    setIsScheduleModalOpen(true);
  };
  
  const renderScheduleModal = () => {
    return (
      <Modal
        isOpen={isScheduleModalOpen}
        onClose={() => setIsScheduleModalOpen(false)}
        title="Schedule Message"
      >
        <div className="schedule-form">
          <div className="form-group">
            <label htmlFor="scheduleTime">Schedule Time</label>
            <input
              type="datetime-local"
              id="scheduleTime"
              className="datetimepicker"
              value={scheduleTime}
              onChange={(e) => setScheduleTime(e.target.value)}
              min={new Date().toISOString().slice(0, 16)}
            />
          </div>
          
          <div className="schedule-summary">
            <p><strong>Message Type:</strong> {messageType}</p>
            <p><strong>Groups Selected:</strong> {selectedGroups.length}</p>
            <p><strong>Message Preview:</strong> {message.substring(0, 50)}{message.length > 50 ? '...' : ''}</p>
            {scheduleTime && (
            <p><strong>Scheduled Time:</strong> {new Date(scheduleTime).toLocaleString()}</p>
          )}
          </div>
          
          <div className="schedule-actions">
            <button
              className="cancel-button"
              onClick={() => setIsScheduleModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="submit-button"
              onClick={() => sendMessage(true)}
              disabled={sending}
            >
              Schedule Message
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  
  return (
    <div className="content-container">
      <div className="page-header">
        <h1>Send Message</h1>
      </div>
      
      {statusMessage && (
        <div className={`status-message ${statusType}`}>
          {statusMessage}
          {messageJobId && (
            <p>
              <button
                className="action-button view-button"
                onClick={() => navigate('/jobs')}
                style={{ marginTop: '10px', backgroundColor: '#667eea', color: 'white' }}
              >
                View All Jobs
              </button>
            </p>
          )}
        </div>
      )}
      
      <div className="send-message-container">
        <div className="select-groups-section">
          <div className="section-header">
            <h2>Select Groups</h2>
            <div className="select-all-container">
              <input 
                type="checkbox" 
                id="selectAll" 
                checked={selectAll}
                onChange={handleSelectAllChange}
              />
              <label htmlFor="selectAll">Select All</label>
              <span className="selected-count">
                {selectedGroups.length} of {groups.length} selected
              </span>
            </div>
          </div>
          
          <div className="groups-list">
            {loading ? (
              <div className="loading-container">
                <div className="loader"></div>
                <p>Loading groups...</p>
              </div>
            ) : groups.length === 0 ? (
              <div className="no-data">No groups found</div>
            ) : (
              <table className="groups-table">
                <thead>
                  <tr>
                    <th className="select-column">Select</th>
                    <th>Group Name</th>
                  </tr>
                </thead>
                <tbody>
                  {groups.map(group => (
                    <tr key={group.groupId}>
                      <td className="select-column">
                        <input 
                          type="checkbox" 
                          checked={selectedGroups.includes(group.groupId)}
                          onChange={() => handleGroupSelection(group.groupId)}
                        />
                      </td>
                      <td>{group.groupName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        
        <div className="compose-message-section">
          <div className="section-header">
            <h2>Compose Message</h2>
          </div>
          
          <div className="message-form">
            <div className="message-type-selector">
              <button 
                className={`type-button ${messageType === 'text' ? 'active' : ''}`}
                onClick={() => handleMessageTypeChange('text')}
              >
                Text Message
              </button>
              <button 
                className={`type-button ${messageType === 'media' ? 'active' : ''}`}
                onClick={() => handleMessageTypeChange('media')}
              >
                Media Message
              </button>
            </div>
            
            {messageType === 'text' ? (
              <div className="form-group">
                <label htmlFor="messageContent">Message Content</label>
                <textarea 
                  id="messageContent" 
                  rows="6" 
                  placeholder="Type your message here..."
                  value={message}
                  onChange={handleMessageChange}
                ></textarea>
              </div>
            ) : (
              <div className="form-group">
                <label>Media File</label>
                <div className="file-input-container">
                  <input 
                    type="file" 
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                  />
                  <button 
                    type="button" 
                    className="file-select-button"
                    onClick={triggerFileInput}
                  >
                    Select File
                  </button>
                  <span className="file-name">
                    {mediaFile ? mediaFile.name : 'No file selected'}
                  </span>
                </div>
                
                {mediaPreview && (
                  <div className="media-preview">
                    <img src={mediaPreview} alt="Preview" />
                  </div>
                )}
                
                {mediaFile && !mediaPreview && (
                  <div className="file-preview">
                    <div className="file-icon">📄</div>
                    <div className="file-info">
                      <div>{mediaFile.name}</div>
                      <div className="file-size">{(mediaFile.size / 1024).toFixed(2)} KB</div>
                    </div>
                  </div>
                )}
                
                <div className="form-group media-caption">
                  <label htmlFor="mediaCaption">Caption (Optional)</label>
                  <textarea 
                    id="mediaCaption" 
                    rows="3" 
                    placeholder="Add a caption for your media..."
                    value={message}
                    onChange={handleMessageChange}
                  ></textarea>
                </div>
              </div>
            )}
            
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
              <button 
                className="send-button" 
                onClick={() => sendMessage(false)}
                disabled={sending || selectedGroups.length === 0}
              >
                {sending ? 'Sending...' : 'Send Now'}
              </button>
              
              <button 
                className="send-button" 
                onClick={handleScheduleClick}
                disabled={sending || selectedGroups.length === 0}
                style={{ background: 'linear-gradient(to right, #4A00E0, #8E2DE2)' }}
              >
                Schedule Message
              </button>
            </div>
            
            {messageJobId && messageProgress && (
              <div className="message-progress-container">
                <h3>Sending Progress</h3>
                <div className="progress-bar-container">
                  <div 
                    className="progress-bar" 
                    style={{ width: `${messageProgress.progress.percentComplete}%` }}
                  ></div>
                </div>
                <div className="progress-stats">
                  <div>Status: {messageProgress.status}</div>
                  <div>Progress: {messageProgress.progress.completed} of {messageProgress.progress.total} ({messageProgress.progress.percentComplete}%)</div>
                  <div>Successful: {messageProgress.results.success}</div>
                  <div>Failed: {messageProgress.results.failed}</div>
                  <div>Time elapsed: {Math.round(messageProgress.elapsedTimeMs / 1000)} seconds</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {renderScheduleModal()}
    </div>
  );
};

export default SendMessage;
