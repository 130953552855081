// client/src/components/Login.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [restartStatus, setRestartStatus] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value
    });
  };

  const restartSessions = async (sessions) => {
    if (!sessions || sessions.length === 0) {
      setRestartStatus('No active sessions found. Redirecting to dashboard...');
      setTimeout(() => {
        navigate('/dashboard');
      }, 1000);
      return;
    }

    setRestartStatus('Reconnecting to WhatsApp sessions...');
    
    // Restart each session sequentially
    for (let i = 0; i < sessions.length; i++) {
      const session = sessions[i];
      
      try {
        setRestartStatus(`Reconnecting session ${i + 1} of ${sessions.length}...`);
        sessionStorage.setItem('currentSessionId', session.sessionId);
        await fetch(`/api/session/${session.sessionId}/restart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        });
      } catch (err) {
        console.error(`Error restarting session ${session.sessionId}:`, err);
        // Continue with other sessions even if one fails
      }
    }
    
    setRestartStatus('All sessions reconnected! Redirecting to dashboard...');
    
    // Navigate to dashboard after a short delay
    setTimeout(() => {
      navigate('/dashboard');
    }, 1000);
  };

  const fetchAndRestartSessions = async (token) => {
    try {
      setRestartStatus('Fetching active sessions...');
      
      // Set a timeout to prevent getting stuck if the request takes too long
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Request timeout')), 10000)
      );
      
      // Create the fetch promise
      const fetchPromise = fetch('/api/sessions', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      // Race the fetch against the timeout
      const response = await Promise.race([fetchPromise, timeoutPromise]);
      
      if (!response.ok) {
        // Handle non-200 responses explicitly
        throw new Error(`Server returned ${response.status}: ${response.statusText}`);
      }
      
      const data = await response.json();
      
      if (data.sessions) {
        await restartSessions(data.sessions);
      } else {
        // If no sessions, redirect to dashboard
        setRestartStatus('No active sessions found. Redirecting to dashboard...');
        setTimeout(() => {
          navigate('/dashboard');
        }, 1000);
      }
    } catch (err) {
      console.error('Error fetching sessions:', err);
      // Redirect to dashboard despite error
      setRestartStatus(`Network error or server unavailable. Redirecting to dashboard...`);
      setTimeout(() => {
        navigate('/dashboard');
      }, 1000);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    setRestartStatus('');

    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
        credentials: 'include'
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }

      // Store token in localStorage
      sessionStorage.setItem('token', data.token);
      
      // Fetch sessions and restart them
      await fetchAndRestartSessions(data.token);

    } catch (err) {
      setError(err.message || 'Something went wrong. Please try again.');
      setLoading(false);
      setRestartStatus('');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-header">
          <h1>Welcome Back</h1>
          <p>Please enter your credentials to login</p>
        </div>

        {error && <div className="error-message">{error}</div>}
        {restartStatus && <div className="status-message">{restartStatus}</div>}

        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <input
              type="text"
              id="username"
              name="username"
              value={credentials.username}
              onChange={handleChange}
              required
              placeholder="Enter your username"
              disabled={loading || restartStatus}
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              id="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              required
              placeholder="Enter your password"
              disabled={loading || restartStatus}
            />
          </div>

          <button
            type="submit"
            className="login-button"
            disabled={loading || restartStatus}
          >
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>

        <div className="login-footer">
          <p>Don't have an account? <a href="/register">Sign up</a></p>
          <a href="/forgot-password">Forgot password?</a>
        </div>
      </div>
    </div>
  );
};

export default Login;

