// client/src/components/GroupChats.jsx
import React, { useState, useEffect } from 'react';
import './GroupChats.css';

const GroupChats = () => {
  const [groupChats, setGroupChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(null);
  
  useEffect(() => {
    fetchGroupChats();
  }, []);
  
  const fetchGroupChats = async () => {
    try {
      setLoading(true);
      setError('');
      
      // Get current session ID from localStorage or context
      const sessionId = sessionStorage.getItem('currentSessionId');
      
      if (!sessionId) {
        setError('No active session found. Please connect WhatsApp first.');
        setLoading(false);
        return;
      }
      
      const response = await fetch(`/api/groupChats/${sessionId}`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      
      if (data.success) {
        setGroupChats(data.groups || []);
      } else {
        setError(data.message || 'Failed to fetch group chats');
      }
    } catch (err) {
      setError('Error fetching group chats: ' + err.message);
      console.error('Error fetching group chats:', err);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchGroupDetails = async (groupId) => {
    try {
      const sessionId = sessionStorage.getItem('currentSessionId');
      
      if (!sessionId) {
        setError('No active session found');
        return;
      }
      
      setLoading(true);
      const response = await fetch(`/api/groupChats/${sessionId}/${groupId}`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      
      if (data.success) {
        setSelectedGroup(data.group);
      } else {
        setError(data.message || 'Failed to fetch group details');
      }
    } finally {
      setLoading(false);
    }
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    
    return `${day}-${month}-${year} ${hours}:${minutes} PM`;
  };
  
  const renderGroupList = () => {
    if (loading && groupChats.length === 0) {
      return <div className="loading-container"><div className="loader"></div><p>Loading group chats...</p></div>;
    }
    
    if (error && groupChats.length === 0) {
      return <div className="error-message">{error}</div>;
    }
    
    if (groupChats.length === 0) {
      return <div className="no-data">No group chats found</div>;
    }
    
    return (
      <div className="group-list-container">
        <div className="group-actions">
          <div className="left-actions">
            <button className="action-button">Copy</button>
            <button className="action-button">CSV</button>
            <button className="action-button">PDF</button>
            <button className="action-button">Excel/Print</button>
            <button className="action-button column-visibility">
              Column visibility <span className="dropdown-icon">▼</span>
            </button>
          </div>
          <div className="right-actions">
            <div className="search-container">
              <label>Search:</label>
              <input type="text" className="search-input" />
            </div>
          </div>
        </div>

        <div className="groups-table-container">
          <table className="groups-table">
            <thead>
              <tr>
                <th>#</th>
                <th>User</th>
                <th>Group Name</th>
                <th>
                  Sender ID / <br />
                  Admin Numbers
                </th>
                <th>Group Link</th>
                <th>Group QR Code</th>
                <th>
                  Group Members <br />
                  Details
                </th>
                <th>
                  Members <br />
                  Count
                </th>
                <th>
                  Admin <br />
                  Count
                </th>
                <th>Status</th>
                <th>
                  Entry <br />
                  Date
                </th>
                <th>
                  Updated <br />
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {groupChats.map((group, index) => (
                <tr key={group.groupId}>
                  <td>{index + 1}</td>
                  <td className="user-cell">SUPER ADMIN</td>
                  <td>{group.groupName}</td>
                  <td>{group.adminPhone || '918121885123'}</td>
                  <td className="link-cell">
                    <a href={group.groupLink || '#'} target="_blank" rel="noopener noreferrer" className="group-link">
                      {group.groupLink || 'https://chat.whatsapp.com/xyz'}
                    </a>
                  </td>
                  <td className="qr-cell">
                    <div className="qr-code-container">
                      {/* Placeholder for QR code - you can use an actual QR code library here */}
                      <div className="qr-code-placeholder"></div>
                    </div>
                  </td>
                  <td className="members-details-cell">
                    <div className="success-count">
                      Success count: {group.successCount || group.participantCount}
                    </div>
                    <div className="failure-count">
                      Failure count: {group.failureCount || 0}
                    </div>
                  </td>
                  <td>{group.participantCount}</td>
                  <td>{group.adminCount}</td>
                  <td className="status-cell">
                    <span className="status-badge active">Active</span>
                  </td>
                  <td className="date-cell">
                    {formatDate(group.creationDate || new Date())}
                  </td>
                  <td className="date-cell">
                    {formatDate(group.updatedDate || new Date())}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  
  const renderGroupDetails = () => {
    if (!selectedGroup) return null;
    
    return (
      <div className="group-details">
        <div className="group-details-header">
          <h2>{selectedGroup.groupName}</h2>
          <button className="close-button" onClick={() => setSelectedGroup(null)}>×</button>
        </div>
        
        <div className="group-meta">
          <div className="meta-item">
            <span className="meta-label">Group ID:</span>
            <span className="meta-value">{selectedGroup.groupId}</span>
          </div>
          <div className="meta-item">
            <span className="meta-label">Created:</span>
            <span className="meta-value">{formatDate(selectedGroup.creationDate)}</span>
          </div>
          <div className="meta-item">
            <span className="meta-label">Owner:</span>
            <span className="meta-value">{selectedGroup.owner}</span>
          </div>
          <div className="meta-item">
            <span className="meta-label">Total Participants:</span>
            <span className="meta-value">{selectedGroup.participantCount}</span>
          </div>
          <div className="meta-item">
            <span className="meta-label">Admins:</span>
            <span className="meta-value">{selectedGroup.adminCount}</span>
          </div>
        </div>
        
        <h3>Participants</h3>
        <div className="participants-list">
          <table>
            <thead>
              <tr>
                <th>Phone Number</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {selectedGroup.participants.map((participant) => (
                <tr key={participant.phoneNumber}>
                  <td>{participant.phoneNumber}</td>
                  <td>
                    {participant.isSuperAdmin ? 'Super Admin' : participant.isAdmin ? 'Admin' : 'Member'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        {selectedGroup.lastMessage && (
          <>
            <h3>Last Message</h3>
            <div className="last-message">
              <p><strong>From:</strong> {selectedGroup.lastMessage.sender}</p>
              <p><strong>Time:</strong> {formatDate(new Date(selectedGroup.lastMessage.timestamp * 1000))}</p>
              <p><strong>Message:</strong> {selectedGroup.lastMessage.body || '(Media or non-text message)'}</p>
            </div>
          </>
        )}
      </div>
    );
  };
  
  return (
    <div className="content-container">
      <div className="page-header">
        <h1>Group List</h1>
        <div className="action-header">
            <button className="add-group-button">+ Add Group Contact</button>
        </div>
      </div>
      

      
      <div className="group-chats-content">
        {selectedGroup ? renderGroupDetails() : renderGroupList()}
      </div>
    </div>
  );
};

export default GroupChats;
