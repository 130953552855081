// client/src/App.js
import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Instances from './components/Instances';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import GroupChats from './components/GroupChats';
import SendMessage from './components/SendMessage';
import Jobs from './components/Jobs'; // Import the new Jobs component
import './App.css';

// Simple check if user is authenticated
const isAuthenticated = () => !!sessionStorage.getItem('token');

// Component for protected routes
const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? (
    <>
      <Header />
      <Sidebar />
      {children}
    </>
  ) : (
    <Navigate to="/login" />
  );
};

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } />
          <Route path="/instances" element={
            <PrivateRoute>
              <Instances />
            </PrivateRoute>
          } />
          <Route path="/compose" element={
            <PrivateRoute>
              <SendMessage />
            </PrivateRoute>
          } />
          <Route path="/jobs" element={
            <PrivateRoute>
              <Jobs />
            </PrivateRoute>
          } />
          <Route path="/contacts" element={
            <PrivateRoute>
              <div className="content-container">
                <h1>Contacts</h1>
                <p>Contacts management will be implemented here.</p>
              </div>
            </PrivateRoute>
          } />
          <Route path="/groups" element={
            <PrivateRoute>
              <GroupChats />
            </PrivateRoute>
          } />
          <Route path="/users" element={
            <PrivateRoute>
              <div className="content-container">
                <h1>Manage Users</h1>
                <p>User management will be implemented here.</p>
              </div>
            </PrivateRoute>
          } />
          <Route path="/reports" element={
            <PrivateRoute>
              <div className="content-container">
                <h1>MIS Reports</h1>
                <p>Reports will be displayed here.</p>
              </div>
            </PrivateRoute>
          } />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
