// client/src/components/Sidebar.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
  const location = useLocation();

  const menuItems = [
    { name: 'Dashboard', path: '/dashboard', icon: '📊' },
    { name: 'Instances', path: '/instances', count: 0, icon: '📱'},
    { name: 'Compose', path: '/compose', icon: '✉️' },
    { name: 'Message Jobs', path: '/jobs', icon: '🕗' }, // New Jobs menu item
    { name: 'Contacts', path: '/contacts', icon: '👥' },
    { name: 'Groups', path: '/groups', icon: '👪' },
    { name: 'Manage Users', path: '/users', icon: '👤' },
    { name: 'MIS Reports', path: '/reports', icon: '📝' }
  ];

  return (
    <div className="sidebar">
      {menuItems.map((item, index) => (
        <Link
          key={index}
          to={item.path}
          className={`sidebar-item ${location.pathname === item.path ? 'active' : ''}`}
        >
          <span className="sidebar-icon">{item.icon}</span>
          <span className="sidebar-text">{item.name}</span>
        </Link>
      ))}
    </div>
  );
};

export default Sidebar;
