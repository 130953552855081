// client/src/components/Dashboard.jsx
import React from 'react';
import './Dashboard.css';

const Dashboard = () => {
  // Stats data
  const stats = [
    { title: 'Total Groups', value: '0', icon: '👪' },
    { title: 'Total Members', value: '0', icon: '👥' },
    { title: 'Messages Sent', value: '0', icon: '✉️' },
    { title: 'Delivery Rate', value: '0%', icon: '📊' },
    { title: 'Account Balance', value: '0', icon: '💰' }
  ];

  // Quick actions
  const quickActions = [
    { title: 'Compose Message', icon: '✉️', action: () => console.log('Compose') },
    { title: 'Add New Group', icon: '👪', action: () => console.log('Add group') },
    { title: 'Import from Excel', icon: '📄', action: () => console.log('Import') },
    { title: 'View Reports', icon: '📝', action: () => console.log('Reports') }
  ];

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-title">Dashboard</h1>

      {/* Stats cards */}
      <div className="stats-container">
        {stats.map((stat, index) => (
          <div key={index} className="stat-card">
            <div className="stat-icon">{stat.icon}</div>
            <div className="stat-content">
              <div className="stat-title">{stat.title}</div>
              <div className="stat-value">{stat.value}</div>
            </div>
          </div>
        ))}
      </div>

      {/* Recent Messages */}
      <div className="dashboard-section">
        <div className="section-header">
          <h2>Recent Messages</h2>
          <a href="#" className="view-all">View All</a>
        </div>
        <div className="section-content">
          <div className="no-messages">No recent messages</div>
        </div>
      </div>

      {/* Quick Actions */}
      <div className="dashboard-section">
        <div className="section-header">
          <h2>Quick Actions</h2>
        </div>
        <div className="quick-actions">
          {quickActions.map((action, index) => (
            <div key={index} className="action-card" onClick={action.action}>
              <div className="action-icon">{action.icon}</div>
              <div className="action-title">{action.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
