// client/src/components/QRCodeDisplay.jsx
import React, { useState, useEffect } from 'react';
import './QRCodeDisplay.css';

const QRCodeDisplay = ({ sessionId, onConnected, onError }) => {
  const [qrUrl, setQrUrl] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [qrRefreshTimer, setQrRefreshTimer] = useState(15);
  const [error, setError] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  
  // Fetch the QR code image with authentication
  const fetchAuthenticatedQRImage = async (sessionId, timestamp) => {
    try {
      // Get the auth token from localStorage
      const token = sessionStorage.getItem('token');
      
      // Make authenticated request to fetch the QR image
      const response = await fetch(`/api/${sessionId}/QR?t=${timestamp}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Failed to fetch QR image: ${response.status}`);
      }
      
      // Convert the response to a blob and create an object URL
      const imageBlob = await response.blob();
      const objectUrl = URL.createObjectURL(imageBlob);
      
      return objectUrl;
    } catch (error) {
      console.error('Error fetching QR image:', error);
      setError('Failed to load QR image');
      return null;
    }
  };
  
  // Function to fetch QR code with retry mechanism
  const fetchQRCode = async (retryCount = 0) => {
    try {
      // Generate a unique timestamp to prevent caching
      const timestamp = new Date().getTime();
      
      // Add a small delay before initial fetch to ensure server has QR ready
      if (isInitializing && retryCount === 0) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        setIsInitializing(false);
      }
      
      // Get the auth token
      const token = sessionStorage.getItem('token');
      
      // Make the request for the QR code status
      const response = await fetch(`/api/${sessionId}/status`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      const data = await response.json();
      
      // Only proceed if session exists and is not yet connected
      if (data && !data.connected) {
        // Reset image loaded state
        setImageLoaded(false);
        
        // Fetch the authenticated QR image
        const objectUrl = await fetchAuthenticatedQRImage(sessionId, timestamp);
        
        if (objectUrl) {
          setQrUrl(objectUrl);
        }
      } else if (data && data.connected) {
        // Handle already connected state
        setIsConnected(true);
        onConnected && onConnected(sessionId);
        return;
      }
      
      // Reset the countdown timer
      setQrRefreshTimer(15);
      
    } catch (err) {
      console.error('Error fetching QR code:', err);
      
      // Retry logic for initial load (up to 3 retries)
      if (retryCount < 3) {
        console.log(`Retrying QR code fetch (${retryCount + 1}/3)...`);
        setTimeout(() => fetchQRCode(retryCount + 1), 1000);
        return;
      }
      
      setError('Failed to fetch QR code. Please try again.');
      onError && onError(err);
    }
  };
  
  // Function to check connection status
  const checkStatus = async () => {
    try {
      const token = sessionStorage.getItem('token');
      
      const response = await fetch(`/api/${sessionId}/status`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      const data = await response.json();
      
      if (data.connected) {
        setIsConnected(true);
        // Store session ID in localStorage for later use
        sessionStorage.setItem('currentSessionId', sessionId);
        // Notify parent component
        onConnected && onConnected(sessionId);
      }
      
      return data.connected;
      
    } catch (err) {
      console.error('Error checking status:', err);
      return false;
    }
  };
  
  // Handle image load success
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  
  // Handle image load error
  const handleImageError = () => {
    setImageLoaded(false);
    // If the image fails to load on initial render, try refreshing it once
    if (isInitializing) {
      fetchQRCode(1);
    }
  };
  
  // Cleanup function to prevent memory leaks
  useEffect(() => {
    return () => {
      // Clean up any object URLs when component unmounts
      if (qrUrl && qrUrl.startsWith('blob:')) {
        URL.revokeObjectURL(qrUrl);
      }
    };
  }, [qrUrl]);
  
  // Initial fetch of QR code
  useEffect(() => {
    if (sessionId && !isConnected) {
      fetchQRCode();
    }
  }, [sessionId]);
  
  // Set up the timer for QR code refresh
  useEffect(() => {
    if (!sessionId || isConnected) return;
    
    // Create countdown timer
    const countdownInterval = setInterval(() => {
      setQrRefreshTimer(prev => prev - 1);
    }, 1000);
    
    // Clean up interval
    return () => clearInterval(countdownInterval);
  }, [sessionId, isConnected]);
  
  // When timer reaches 0, refresh QR and reset timer
  useEffect(() => {
    if (qrRefreshTimer <= 0 && !isConnected) {
      fetchQRCode();
    }
  }, [qrRefreshTimer, isConnected]);
  
  // Set up polling for status check
  useEffect(() => {
    if (!sessionId || isConnected) return;
    
    // Check status immediately
    checkStatus();
    
    // Then set up interval to check every 3 seconds
    const statusInterval = setInterval(async () => {
      const connected = await checkStatus();
      if (connected) {
        clearInterval(statusInterval);
      }
    }, 3000);
    
    // Clean up interval
    return () => clearInterval(statusInterval);
  }, [sessionId, isConnected]);
  
  if (error) {
    return (
      <div className="qr-error">
        <p>{error}</p>
        <button onClick={() => fetchQRCode()}>Retry</button>
      </div>
    );
  }
  
  return (
    <div className="qr-container">
      {isConnected ? (
        <div className="connection-success">
          <div className="success-icon">✓</div>
          <h2>Successfully Connected!</h2>
          <p>Your WhatsApp instance is now ready to use.</p>
        </div>
      ) : (
        <>
          <div className="qr-header">
            <div className="qr-instructions">
              <h2>Connect to WhatsApp</h2>
              <ol>
                <li>Open WhatsApp on your phone</li>
                <li>Tap Menu or Settings and select WhatsApp Web</li>
                <li>Point your phone to this screen to capture the code</li>
              </ol>
            </div>
          </div>
          
          <div className="qr-image-container">
            {qrUrl && (
              <>
                {!imageLoaded && (
                  <div className="qr-loading">
                    <div className="loader"></div>
                    <p>Loading QR code...</p>
                  </div>
                )}
                <img 
                  src={qrUrl} 
                  alt="QR Code" 
                  className={`qr-image ${imageLoaded ? 'loaded' : 'loading'}`}
                  onLoad={handleImageLoad}
                  onError={handleImageError}
                  style={{ display: imageLoaded ? 'block' : 'none' }}
                />
              </>
            )}
            <div className="qr-refresh-timer">
              {imageLoaded ? `Refreshing in ${qrRefreshTimer} seconds` : 'Preparing QR code...'}
            </div>
            {!imageLoaded && qrUrl && (
              <button 
                className="qr-manual-refresh" 
                onClick={() => fetchQRCode()}
              >
                Load QR Code
              </button>
            )}
          </div>
          
          <div className="qr-status">
            <div className="status-indicator"></div>
            <span>Waiting for connection...</span>
          </div>
        </>
      )}
    </div>
  );
};

export default QRCodeDisplay;
