// client/src/components/Jobs.jsx
import React, { useState, useEffect, useCallback } from 'react';
import Modal from './Modal';
import './Jobs.css';

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentFilter, setCurrentFilter] = useState('all');
  const [selectedJob, setSelectedJob] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [refreshInterval, setRefreshInterval] = useState(null);
  
  // Form state for scheduling
  const [scheduleForm, setScheduleForm] = useState({
    message: '',
    scheduleTime: '',
    groupIds: [],
    messageType: 'text',
    file: null
  });
  
  // Function to fetch all jobs
  const fetchJobs = useCallback(async () => {
    setError('');
    try {
      const response = await fetch('/api/message-jobs', {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setJobs(data.jobs || []);
      } else {
        setError(data.message || 'Failed to fetch jobs');
      }
    } catch (err) {
      setError('Error fetching jobs: ' + err.message);
      console.error('Error fetching jobs:', err);
    } finally {
      setLoading(false);
    }
  }, []);
  
  // Function to fetch job details
  const fetchJobDetails = async (jobId) => {
    try {
      const response = await fetch(`/api/message-jobs/${jobId}`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setSelectedJob(data.job);
        setIsModalOpen(true);
      } else {
        setError(data.message || 'Failed to fetch job details');
      }
    } catch (err) {
      setError('Error fetching job details: ' + err.message);
      console.error('Error fetching job details:', err);
    }
  };
  
  // Function to retry failed messages
  const retryFailedMessages = async (jobId) => {
    try {
      const sessionId = sessionStorage.getItem('currentSessionId');
      
      if (!sessionId) {
        setError('No active session found. Please connect WhatsApp first.');
        return;
      }
      
      const response = await fetch(`/api/message-jobs/${jobId}/retry`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ sessionId })
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Close modal if open
        setIsModalOpen(false);
        // Refresh jobs
        fetchJobs();
      } else {
        setError(data.message || 'Failed to retry messages');
      }
    } catch (err) {
      setError('Error retrying messages: ' + err.message);
      console.error('Error retrying messages:', err);
    }
  };
  
  // Function to delete a job
  const deleteJob = async (jobId) => {
    try {
      const response = await fetch(`/api/message-jobs/${jobId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Remove job from list
        setJobs(jobs.filter(job => job.id !== jobId));
        // Close modals
        setIsConfirmDeleteOpen(false);
        setIsModalOpen(false);
      } else {
        setError(data.message || 'Failed to delete job');
      }
    } catch (err) {
      setError('Error deleting job: ' + err.message);
      console.error('Error deleting job:', err);
    }
  };
  
  // Function to open delete confirmation modal
  const confirmDelete = (job) => {
    setJobToDelete(job);
    setIsConfirmDeleteOpen(true);
  };
  
  // Function to format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  // Function to filter jobs
  const getFilteredJobs = () => {
    if (currentFilter === 'all') return jobs;
    
    return jobs.filter(job => {
      switch (currentFilter) {
        case 'active':
          return job.status === 'processing' || job.status === 'pending';
        case 'completed':
          return job.status === 'completed';
        case 'failed':
          return job.status === 'failed';
        case 'scheduled':
          return job.status === 'scheduled';
        default:
          return true;
      }
    });
  };
  
  // Fetch jobs on mount
  useEffect(() => {
    // Initial fetch
    fetchJobs();
    
    // Set up an interval to refresh active jobs
    const interval = setInterval(() => {
      // Check directly if we have active jobs to avoid dependency on jobs state
      fetchJobs();
    }, 15000); // Refresh less frequently (every 15 seconds)
    
    setRefreshInterval(interval);
    
    // Cleanup function
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [fetchJobs]); 
  
  // Render status badge
  const renderStatusBadge = (status) => {
    return (
      <span className={`status-badge ${status.toLowerCase()}`}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    );
  };
  
  // Render progress bar
  const renderProgressBar = (progress) => {
    return (
      <div>
        <div className="progress-bar-container">
          <div 
            className="progress-bar" 
            style={{ width: `${progress.percentComplete}%` }}
          ></div>
        </div>
        <div className="progress-stats">
          <span>
            {progress.completed} / {progress.total} ({progress.percentComplete}%)
          </span>
        </div>
      </div>
    );
  };
  
  // Render success/fail counts
  const renderCounts = (results) => {
    return (
      <div className="counts-container">
        <span className="success-count">
          ✓ {results.success}
        </span>
        <span className="failed-count">
          ✗ {results.failed}
        </span>
      </div>
    );
  };
  
  // Render job list
  const renderJobsList = () => {
    const filteredJobs = getFilteredJobs();
    
    if (loading) {
      return (
        <div className="loading-container">
          <div className="loader"></div>
          <p>Loading jobs...</p>
        </div>
      );
    }
    
    if (error) {
      return (
        <div className="error-message">
          {error}
        </div>
      );
    }
    
    if (filteredJobs.length === 0) {
      return (
        <div className="empty-state">
          <div className="empty-icon">📭</div>
          <div className="empty-message">
            {currentFilter === 'all' 
              ? 'No message jobs found. Send some messages to get started!'
              : `No ${currentFilter} jobs found.`}
          </div>
          {currentFilter === 'all' && (
            <a href="/compose" className="empty-action">Send Messages</a>
          )}
        </div>
      );
    }
    
    return (
      <div className="jobs-list">
        <table className="jobs-table">
          <thead>
            <tr>
              <th>Job ID</th>
              <th>Type</th>
              <th>Status</th>
              <th>Progress</th>
              <th>Results</th>
              <th>Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredJobs.map(job => (
              <tr key={job.id}>
                <td>{job.id}</td>
                <td>
                  {job.isScheduled 
                    ? `Scheduled ${job.messageType}` 
                    : job.messageType}
                </td>
                <td>{renderStatusBadge(job.status)}</td>
                <td>{renderProgressBar(job.progress)}</td>
                <td>{renderCounts(job.results)}</td>
                <td>{formatDate(job.createdAt)}</td>
                <td className="actions-cell">
                  <button 
                    className="action-button view-button"
                    onClick={() => fetchJobDetails(job.id)}
                  >
                    View
                  </button>
                  
                  {job.status === 'completed' && job.results.failed > 0 && (
                    <button 
                      className="action-button retry-button"
                      onClick={() => retryFailedMessages(job.id)}
                    >
                      Retry Failed
                    </button>
                  )}
                  
                  <button 
                    className="action-button delete-button"
                    onClick={() => confirmDelete(job)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
  // Render job details modal
  const renderJobDetailsModal = () => {
    if (!selectedJob) return null;
    
    return (
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Job Details: ${selectedJob.id}`}
      >
        <div className="job-info">
          <div className="info-item">
            <span className="info-label">Status</span>
            <span className="info-value">{renderStatusBadge(selectedJob.status)}</span>
          </div>
          
          <div className="info-item">
            <span className="info-label">Message Type</span>
            <span className="info-value">{selectedJob.messageType}</span>
          </div>
          
          <div className="info-item">
            <span className="info-label">Total Groups</span>
            <span className="info-value">{selectedJob.progress.total}</span>
          </div>
          
          <div className="info-item">
            <span className="info-label">Success</span>
            <span className="info-value success-count">{selectedJob.results.success}</span>
          </div>
          
          <div className="info-item">
            <span className="info-label">Failed</span>
            <span className="info-value failed-count">{selectedJob.results.failed}</span>
          </div>
          
          <div className="info-item">
            <span className="info-label">Elapsed Time</span>
            <span className="info-value">
              {Math.round(selectedJob.elapsedTimeMs / 1000)} seconds
            </span>
          </div>
          
          {selectedJob.isScheduled && (
            <div className="info-item">
              <span className="info-label">Scheduled Time</span>
              <span className="info-value">{formatDate(selectedJob.scheduledTime)}</span>
            </div>
          )}
        </div>
        
        <div className="job-message">
          <div className="message-header">Message Content</div>
          <div className="message-content">
            {selectedJob.messageContent || 'No text content (media message)'}
          </div>
        </div>
        
        <div className="details-progress">
          <h3>Message Details</h3>
          <div className="details-table-container">
            <table className="details-table">
              <thead>
                <tr>
                  <th>Group</th>
                  <th>Status</th>
                  <th>Sent At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {selectedJob.details.map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.chatName || detail.chatId}</td>
                    <td className="details-status">
                      {renderStatusBadge(detail.status)}
                    </td>
                    <td>{detail.sentAt ? formatDate(detail.sentAt) : 'N/A'}</td>
                    <td className="details-actions">
                      {detail.status === 'failed' && (
                        <button 
                          className="action-button retry-button"
                          onClick={() => retryFailedMessages(selectedJob.id)}
                        >
                          Retry
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    );
  };
  
  // Render delete confirmation modal
  const renderDeleteConfirmModal = () => {
    if (!jobToDelete) return null;
    
    return (
      <Modal
        isOpen={isConfirmDeleteOpen}
        onClose={() => setIsConfirmDeleteOpen(false)}
        title="Confirm Delete"
      >
        <div className="confirm-content">
          <p>Are you sure you want to delete this job?</p>
          <p>Job ID: <strong>{jobToDelete.id}</strong></p>
          <p>This action cannot be undone.</p>
          
          <div className="schedule-actions">
            <button 
              className="cancel-button"
              onClick={() => setIsConfirmDeleteOpen(false)}
            >
              Cancel
            </button>
            <button 
              className="delete-button"
              onClick={() => deleteJob(jobToDelete.id)}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  
  return (
    <div className="jobs-container">
      <div className="jobs-header">
        <div className="jobs-title-container">
          <h1 className="jobs-title">Message Jobs</h1>
          <span className="jobs-count">{jobs.length}</span>
        </div>
        <div className="jobs-actions">
          <button 
            className="refresh-button"
            onClick={fetchJobs}
            disabled={loading}
          >
            {loading ? 'Refreshing...' : 'Refresh'}
          </button>
        </div>
      </div>
      
      <div className="filter-tabs">
        <button 
          className={`filter-tab ${currentFilter === 'all' ? 'active' : ''}`}
          onClick={() => setCurrentFilter('all')}
        >
          All Jobs
        </button>
        <button 
          className={`filter-tab ${currentFilter === 'active' ? 'active' : ''}`}
          onClick={() => setCurrentFilter('active')}
        >
          Active
        </button>
        <button 
          className={`filter-tab ${currentFilter === 'completed' ? 'active' : ''}`}
          onClick={() => setCurrentFilter('completed')}
        >
          Completed
        </button>
        <button 
          className={`filter-tab ${currentFilter === 'failed' ? 'active' : ''}`}
          onClick={() => setCurrentFilter('failed')}
        >
          Failed
        </button>
        <button 
          className={`filter-tab ${currentFilter === 'scheduled' ? 'active' : ''}`}
          onClick={() => setCurrentFilter('scheduled')}
        >
          Scheduled
        </button>
      </div>
      
      {renderJobsList()}
      {renderJobDetailsModal()}
      {renderDeleteConfirmModal()}
    </div>
  );
};

export default Jobs;
