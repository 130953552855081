// client/src/components/Header.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear authentication token
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('currentSessionId');
    // Navigate to login page
    navigate('/login');
  };

  return (
    <header className="app-header">
      <div className="header-content">
        <div className="header-title">Messaging Dashboard</div>
        <div className="header-actions">
          <button className="logout-button" onClick={handleLogout}>
            <span className="logout-icon">🚪</span>
            <span>Logout</span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
