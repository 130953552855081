// client/src/components/Instances.jsx
import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import QRCodeDisplay from './QRCodeDisplay';
import './Instances.css';

const Instances = () => {
  const [sessions, setSessions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [currentSessionId, setCurrentSessionId] = useState('');
  const [showQRCode, setShowQRCode] = useState(false);
  const [connected, setConnected] = useState(false);
  const [reconnectSession, setReconnectSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isTerminating, setIsTerminating] = useState(false);
  const [terminatingId, setTerminatingId] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [sessionToRemove, setSessionToRemove] = useState(null);
  
  // Fetch all sessions on component mount
  useEffect(() => {
    fetchSessions();
  }, []);
  
  // Function to fetch all sessions
  const fetchSessions = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await fetch('/api/sessions', {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setSessions(data.sessions || []);
      } else {
        console.error('Failed to fetch sessions:', data.message);
        setError('Failed to fetch sessions: ' + data.message);
      }
    } catch (err) {
      console.error('Error fetching sessions:', err);
      setError('Error fetching sessions: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  const openModal = () => {
    // Check if an instance already exists
    if (sessions.length > 0) {
      setError('Only one instance is allowed. Please remove the existing instance first.');
      return;
    }
    
    setIsModalOpen(true);
    setPhoneNumber('');
    setError('');
    setSuccess('');
    setShowQRCode(false);
    setReconnectSession(null);
  };
  
  const openReconnectModal = (session) => {
    setIsModalOpen(true);
    setPhoneNumber(session.phoneNumber);
    setCurrentSessionId(session.sessionId);
    setError('');
    setSuccess('');
    setShowQRCode(true);
    setReconnectSession(session);
  };
  
  const closeModal = () => {
    if (connected) {
      // If connected, refresh sessions list
      fetchSessions();
    }
    
    setIsModalOpen(false);
    setCurrentSessionId('');
    setShowQRCode(false);
    setConnected(false);
    setReconnectSession(null);
  };
  
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setError('');
  };
  
  const createInstance = async (e) => {
    e.preventDefault();
    
    // Simple validation
    if (!phoneNumber) {
      setError('Please enter a phone number');
      return;
    }
    
    setLoading(true);
    
    try {
      const response = await fetch('/api/create-instance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ phoneNumber }),
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setSuccess(data.message || 'Instance created successfully! Please scan the QR code.');
        setCurrentSessionId(data.sessionId);
        
        // Show QR code immediately
        setShowQRCode(true);
      } else {
        setError(data.message || 'Failed to create instance');
      }
    } catch (err) {
      setError('Server error. Please try again later.');
      console.error('Error creating instance:', err);
    } finally {
      setLoading(false);
    }
  };
  
  const handleConnected = (sessionId) => {
    setConnected(true);
    setSuccess('WhatsApp connected successfully!');
    
    // Refresh sessions to show the newly connected one
    fetchSessions();
    
    // Optional: close modal after delay
    setTimeout(() => {
      closeModal();
    }, 3000);
  };
  
  const handleLogout = async (sessionId) => {
    try {
      const response = await fetch(`/api/${sessionId}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      
      if (response.ok) {
        // Refresh sessions list after logout
        fetchSessions();
      } else {
        console.error('Failed to logout session');
        setError('Failed to logout session');
      }
    } catch (err) {
      console.error('Error logging out session:', err.message);
      setError('Error logging out session: ' + err.message);
    }
  };
  
  const openConfirmModal = (session) => {
    setSessionToRemove(session);
    setConfirmModalOpen(true);
  };
  
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
    setSessionToRemove(null);
  };
  
  const handleTerminate = async (sessionId) => {
    setIsTerminating(true);
    setTerminatingId(sessionId);
    setError('');
    
    try {
      const response = await fetch(`/api/session/${sessionId}/terminate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      
      if (response.ok) {
        // Remove the session from the list
        setSessions(sessions.filter(session => session.sessionId !== sessionId));
        setSuccess(`Session terminated successfully`);
        
        // Close confirm modal if it was open
        closeConfirmModal();
      } else {
        const data = await response.json();
        setError(data.message || 'Failed to terminate session');
      }
    } catch (err) {
      console.error('Error terminating session:', err);
      setError('Error terminating session: ' + err.message);
    } finally {
      setIsTerminating(false);
      setTerminatingId(null);
    }
  };
  
  // Function to render connection status with appropriate styling
  const renderStatus = (status) => {
    let statusClass = 'status-indicator';
    let statusText = status;
    
    switch (status) {
      case 'CONNECTED':
        statusClass += ' status-connected';
        break;
      case 'DISCONNECTED':
        statusClass += ' status-disconnected';
        break;
      case 'PENDING':
        statusClass += ' status-pending';
        break;
      default:
        statusClass += ' status-unknown';
    }
    
    return (
      <div className="status-wrapper">
        <span className={statusClass}></span>
        <span className="status-text">{statusText}</span>
      </div>
    );
  };
  
  // Check if an add button should be disabled
  const isAddButtonDisabled = sessions.length > 0;
  
  return (
    <div className="content-container">
      {error && <div className="error-message instances-message">{error}</div>}
      {success && <div className="success-message instances-message">{success}</div>}
      
      <div className="instances-header">
        <div className="instances-title-container">
          <h1 className="instances-title">Instances</h1>
          <span className="instances-count">{sessions.length}</span>
        </div>
        <div className="header-actions">
          <button className="refresh-button" onClick={fetchSessions} disabled={isLoading}>
            {isLoading ? 'Refreshing...' : 'Refresh'}
          </button>
          <button 
            className="add-button" 
            onClick={openModal} 
            disabled={isAddButtonDisabled}
            title={isAddButtonDisabled ? "Remove existing instance first" : "Add new instance"}
          >
            ADD NEW
          </button>
        </div>
      </div>
      
      {/* Sessions List */}
      {sessions.length > 0 ? (
        <div className="sessions-list">
          <table className="sessions-table">
            <thead>
              <tr>
                <th>Phone Number</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sessions.map((session) => (
                <tr key={session.id || session.sessionId}>
                  <td>{session.phoneNumber}</td>
                  <td>{renderStatus(session.status)}</td>
                  <td>{new Date(session.createdAt).toLocaleString()}</td>
                  <td className="actions-cell">
                    <div className="action-buttons-container">
                      {session.status === 'CONNECTED' && (
                        <button
                          className="logout-button"
                          onClick={() => handleLogout(session.sessionId)}
                        >
                          Logout
                        </button>
                      )}
                      
                      {session.status !== 'CONNECTED' && (
                        <button
                          className="reconnect-button"
                          onClick={() => openReconnectModal(session)}
                        >
                          Reconnect
                        </button>
                      )}
                      
                      <button
                        className="remove-button"
                        onClick={() => openConfirmModal(session)}
                        disabled={isTerminating && terminatingId === session.sessionId}
                      >
                        {isTerminating && terminatingId === session.sessionId ? 'Removing...' : 'Remove'}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : isLoading ? (
        <div className="loading-container">
          <div className="loader"></div>
          <p>Loading instances...</p>
        </div>
      ) : (
        <div className="instances-info">
          <p className="warning-text">No Instance, Please connect new one!</p>
        </div>
      )}
      
      {/* Modal for adding new instance or reconnecting */}
      <Modal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        title={showQRCode ? "Connect WhatsApp" : "Add New Instance"}
      >
        {showQRCode ? (
          <QRCodeDisplay 
            sessionId={currentSessionId} 
            onConnected={handleConnected}
            onError={(err) => setError('Failed to connect: ' + (err.message || 'Unknown error'))}
          />
        ) : (
          <form onSubmit={createInstance}>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Enter phone number with country code (e.g., +1234567890)"
                className="form-input"
              />
              {error && <div className="error-message">{error}</div>}
              {success && <div className="success-message">{success}</div>}
            </div>
            <div className="form-actions">
              <button 
                type="button" 
                className="cancel-button" 
                onClick={closeModal}
                disabled={loading}
              >
                Cancel
              </button>
              <button 
                type="submit" 
                className="submit-button"
                disabled={loading}
              >
                {loading ? 'Creating...' : 'Create Instance'}
              </button>
            </div>
          </form>
        )}
      </Modal>
      
      {/* Confirmation Modal for session termination */}
      <Modal
        isOpen={confirmModalOpen}
        onClose={closeConfirmModal}
        title="Confirm Termination"
      >
        <div className="confirm-content">
          <p>Are you sure you want to remove this session?</p>
          {sessionToRemove && (
            <div className="session-details">
              <p><strong>Phone Number:</strong> {sessionToRemove.phoneNumber}</p>
              <p><strong>Status:</strong> {sessionToRemove.status}</p>
              <p className="warning-text">
                This action cannot be undone and will permanently remove the session.
              </p>
            </div>
          )}
          <div className="confirm-actions">
            <button 
              className="cancel-button" 
              onClick={closeConfirmModal}
              disabled={isTerminating}
            >
              Cancel
            </button>
            <button 
              className="delete-button" 
              onClick={() => sessionToRemove && handleTerminate(sessionToRemove.sessionId)}
              disabled={isTerminating}
            >
              {isTerminating ? 'Removing...' : 'Remove Session'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Instances;
